<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div v-if="alert.account" class="form-group col-sm-12">
          <label for="parent_id">Account:</label>
          <input class="form-control" disabled name="account_id" type="text" v-model="alert.account.account_name">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="vehicle_id">Vehicle:</label>
        <Multiselect mode="multiple" required v-model="value_multiple_vehicle" :options="vehicleOptions" valueProp="valueProp" label="label" :searchable="true">
          <template v-slot:multiplelabel="{ values }">
            <div class="multiselect-multiple-label">
              <span v-for="(item2, index2) in values" :key="index2">{{item2.label}},&nbsp;&nbsp;</span>
            </div>
          </template>
        </Multiselect>
      </div>
      <div class="form-group col-sm-12">
        <label for="name">Name:</label>
        <input class="form-control" disabled name="name" type="text" v-model="alert.name">
      </div>
      <div class="form-group col-sm-12">
          <label for="type">Type:</label>
          <select class="form-control" disabled name="type" v-model.number="alert.type">
            <option value="custom" style="color:black;">Custom</option>
            <option value="overspeed" style="color:black;">Overspeed (km/h)</option>
            <option value="stop_duration" style="color:black;">Stop Duration (minutes)</option>
            <option value="offline_duration" style="color:black;">Offline Duration (minutes)</option>
            <option value="idle_duration" style="color:black;">Idle Duration (minutes)</option>
            <option value="ignition_duration" style="color:black;">Ignition Duration (minutes)</option>
            <option value="geofence_inout" style="color:black;">Geofence In Out</option>
            <option value="geofence_in" style="color:black;">Geofence In</option>
            <option value="geofence_out" style="color:black;">Geofence Out</option>
          </select>
      </div>
      <div class="form-group col-sm-12">
          <label for="type">Driver Behavior Type:</label>
          <select class="form-control" name="type" v-model="alert.driver_behavior_type">
            <option value="" style="color:black;">None</option>
            <option value="HB" style="color:black;">Hard Braking (Deceleration)</option>
            <option value="HA" style="color:black;">Hard Acceleration (Acceleration</option>
            <option value="SV" style="color:black;">Speed Violation</option>
            <option value="SB" style="color:black;">Seat Belt Violation</option>
          </select>
      </div>
      <div v-if="alert.type != 'geofence_inout' && alert.type != 'geofence_in' && alert.type != 'geofence_out' && alert.type != 'custom'" class="form-group col-sm-12 required">
          <label for="value">Value:</label>
          <input class="form-control" required name="value" type="number" v-model.number="alert.value">
      </div>
      <div v-if="alert.type == 'custom'" class="form-group col-sm-12 required">
          <label for="value">Protocol:</label>
          <select class="form-control" name="protocol" v-model="alert.protocol" required>
            <option value="">--- Please Select One ---</option>
            <option value="teltonika">Teltonika</option>
            <option value="gt06">GT06</option>
          </select>
      </div>
      <div v-if="alert.type == 'custom'" class="form-group col-sm-12 required">
          <label for="value">Parameter:</label>
          <input class="form-control" required name="value" type="text" v-model="alert.tag">
      </div>
      <div v-if="alert.type == 'custom'" class="form-group col-sm-12 required">
          <label for="value">Operator:</label>
          <select class="form-control" name="tag_type" v-model="alert.tag_type" required>
            <option value="">--- Please Select One ---</option>
            <option value="1">=</option>
            <option value="2">></option>
            <option value="3" v-html="`<`"></option>
          </select>
      </div>
      <div v-if="alert.type == 'custom'" class="form-group col-sm-12 required">
          <label for="value">Value:</label>
          <input class="form-control" required name="value" type="text" v-model="alert.value_custom">
      </div>      
      <div v-if="alert.type == 'geofence_inout' || alert.type == 'geofence_in' || alert.type == 'geofence_out'" class="form-group col-sm-12 required">
          <label for="stop_id">Stop Geofence:</label>
          <select class="form-control" name="stop_id" v-model.number="alert.stop_id" required>
            <option value="">--- Please Select One ---</option>
            <option v-for="st in stops" :key="st" :value="st.id">{{st.stop_name}}</option>
          </select>
      </div>
      <div class="form-group col-sm-12">
          <label for="email">Email Address for Notification (Separate with comma for multiple email address):</label>
          <input class="form-control" name="email" type="text" v-model="alert.email">
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save" :disabled="this.onRequest">
        <router-link class="btn btn-default ml-1" to="/alerts">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Multiselect from '@vueform/multiselect'
  export default {
    computed: {
        ...mapState('alerts', ['alert', 'onRequest']),
        ...mapState('stops', ['stops']),
        ...mapState('vehicles', ['vehicles']),
    },
    components: {
      Multiselect,
    },
    data(){
      return {
        value_multiple_vehicle: null,
        vehicleOptions: [],
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        let res = []
        this.value_multiple_vehicle.map(item => {
          res.push({vehicle_id: item})
        })
        this.alert.alert_vehicles = res
        this.edit(this.alert)
      },
      ...mapActions('alerts', ['edit', 'get']),
      ...mapActions('stops', {getStops: 'get_stop_by_account'}),
      ...mapActions('vehicles', ['get_vehicle_by_account']),
    },
    created() {
      this.$emit('onChildInit', 'Edit Alert')
      this.get(this.$route.params.id)
    },
    watch:{
      alert (nextState, prevState) {
        if(nextState !== prevState){
          if(nextState.account_id){
          this.getStops({account_id: nextState.account_id})
            this.get_vehicle_by_account({account_id: nextState.account_id})
            this.value_multiple_vehicle = nextState.alert_vehicles.map(item => item.vehicle_id)
          }
          return
        }
      },
      vehicles(nextState, prevState){
        if(nextState !== prevState){
          this.vehicleOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.vehicleOptions.push({valueProp:item.id, label:item.vehicle_no})
            })
      }
          return
        }
      },
    }
  }
</script>
