<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 required">
          <label for="parent_id">Parent Account:</label>
          <select class="form-control" name="parent_id" v-model.number="account.parent_id">
            <option value="">--- No Parent Account ---</option>
            <option v-for="acc in accounts" :key="acc" :value="acc.id">{{acc.account_name}}</option>
          </select>
      </div>
      <div class="form-group col-sm-6 required">
          <label for="account_name">Account Name:</label>
          <input class="form-control" required="" name="account_name" type="text" v-model="account.account_name">
      </div>
      <div class="form-group col-sm-6 required">
          <label for="account_code">Account Code:</label>
          <input class="form-control" required="" name="account_code" type="text" v-model="account.account_code" maxlength="4">
      </div>
      <div class="form-group col-sm-4 required">
        <label for="contact_person">Contact Person:</label>
        <input class="form-control" required="" name="contact_person" type="text" v-model="account.contact_person">
      </div>
      <div class="form-group col-sm-4 required">
        <label for="contact_phone">Contact Phone:</label>
        <input class="form-control" required="" name="contact_phone" type="text" v-model="account.contact_phone">
      </div>
      <div class="form-group col-sm-4 required">
        <label for="contact_phone">Email:</label>
        <input class="form-control" required="" name="email" type="email" v-model="account.email">
      </div>
      <div class="form-group col-sm-6 required">
        <label for="status">Account Type:</label>
        <select class="form-control" name="type" v-model.number="account.type">
          <option value="1" style="color:black;">Company</option>
          <option value="2" style="color:black;">Individual</option>
        </select>
      </div>
      <div class="form-group col-sm-6">
        <label for="account_officer">Account Officer:</label>
        <input class="form-control" name="account_officer" type="text" v-model="account.account_officer">
      </div>
      <div class="form-group col-sm-12">
        <label for="contact_phone">Address:</label>
        <textarea class="form-control" name="address" v-model="account.address"></textarea>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="status">Status:</label>
        <select class="form-control" name="status" v-model.number="account.status">
          <option value="1" style="color:black;">Active</option>
          <option value="2" style="color:black;">Inactive</option>
        </select>
      </div>
      <div class="form-group col-sm-9">
        <label for="token_gps_integrator">Token GPS Integrator:</label>
        <input class="form-control" name="token_gps_integrator" type="text" v-model="account.token_gps_integrator">
      </div>
      <div class="form-group col-sm-3">
        <label for="gps_integrator_interval">Interval Sync GPS Integrator (in minutes):</label>
        <input class="form-control" name="gps_integrator_interval" type="text" v-model.number="account.gps_integrator_interval">
      </div>
      <InputImage label="Logo" v-on:selected-image="setImage"/>
      <div v-if="account.pic_url" class="form-group col-sm-12">
        <img :src="account.pic_url" height="200"/>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save" :disabled="this.onRequest">
        <router-link class="btn btn-default ml-1" to="/accounts">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import InputImage from '../components/InputImage.vue'
  export default {
    computed: {
        ...mapState('accounts', ['account', 'onRequest']),
        accounts(){
          return this.$store.state.accounts.accounts.filter(item => item.id !== this.account.id && item.parent_id !== this.account.id) || []
        }
    },
    components:{
      InputImage
    },
    data(){
      return {
        file_image: null,
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.account.file_image = this.file_image
        this.edit(this.account)
      },
      ...mapActions('accounts', ['edit', 'get', 'get_available_parent_accounts']),
      setImage(image){
        this.file_image = image
      }
    },
    created() {
      this.$emit('onChildInit', 'Edit Account')
      this.get_available_parent_accounts()
      this.get(this.$route.params.id)
    }
  }
</script>
